/* set up padding and margin of some main elements */
.main-wrapper {
    margin-top: var(--len-5);
    margin-bottom: var(--len-5);
}

.main-wrapper { display: flex; flex-wrap: wrap; }
.main-wrapper > * { height: fit-content; }

.main { padding: var(--len-4); flex: 0 0 70%; width: 70%; margin-right: 2%; } /* Adjusted width and added margin */
.side { padding-left: var(--len-4); flex: 0 0 28%; width: 28%; } /* Adjusted width */

/* mobile layout: place side to bottom */
@media (max-width: 991px) {
    .main { padding: var(--len-4); flex: 0 0 100%; width: 100%; }
    .side { padding-left: 0; padding-top: var(--len-4); flex: 0 0 100%; width: 100%; }
}

/********** set up break point **********/

.main-wrapper, .header { max-width: 1140px; }
@media (max-width: 1199px) { .main-wrapper, .header { max-width: 960px; } }
@media (max-width: 991px)  { .main-wrapper, .header { max-width: 720px; } }
@media (max-width: 767px)  { .main-wrapper, .header { max-width: 540px; } }
@media (max-width: 575px)  { .main-wrapper, .header { max-width: none; } }

.header-wrapper, .footer { width: 100vw;}
.main-wrapper { width: calc(100vw - 2 * var(--len-3));}

.sidebar {
    order: 2;
    flex: 1 0 30%;
    background-color: #f5f5f5;
    padding: 1em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    position: sticky;
    top: 0;
}

/* Style the sidebar links */
.sidebar a {
    color: #333;
    text-decoration: none;
    margin: 0.5em 0;
}

.sidebar h2 {
    font-size: 1.25em;
    margin-top: 0;
    text-decoration: dashed;
    text-align: center;
}

.sidebar ul {
    list-style-type: none;
    padding: 0;
}

.sidebar ul li {
    margin-bottom: 0.5em;
}

/* Style the sidebar links on hover */
.sidebar a:hover {
    color: #007BFF;
}
  
  /* Make the sidebar stack below the main content on small screens */
  @media (max-width: 768px) {
    main, .sidebar {
        flex: 1 0 100%;
    }
    .sidebar {
        position: static; /* Disable sticky sidebar on small screens */
    }
  }